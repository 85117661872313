/* credit and inspiration goes to: https://codepen.io/albizan */
.nav {
  width: 100%;
  height: 80px;
  position: fixed;
  line-height: 80px;
  text-align: center;
  z-index: 100;
  background-color: transparent;
}

.nav-responsive {
  background-color: #212a3e;
  padding: 0px !important;
}

.nav div.logo {
  float: left;
  cursor: pointer;
  width: 275px;
  margin-top: -28px;
  margin-left: -35px;
  height: auto;
  padding-left: 1rem;
  display: inline-grid;
  transition: all 0.4s ease;
}

.logo-responsive {
  width: 242px !important;
  height: auto !important;
}

.nav div.logo a {
  text-decoration: none;
  color: #212a3e;
  font-size: 2.25rem;
  line-height: initial;
}

.nav div.logo a:hover {
  opacity: 0.8;
}

.nav div.nav-primary-list {
  height: 80px;
  float: right;
}

.nav div.nav-primary-list ul {
  width: 100%;
  height: 80px;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav div.nav-primary-list ul li {
  width: auto;
  height: 80px;
  padding: 0;
  padding-right: 1.25rem;
}

.nav div.nav-primary-list ul li a {
  font-family: "Montserrat", Helvetica, sans-serif;
  text-decoration: none;
  color: #212a3e;
  line-height: 80px;
  font-size: 1.3rem;
}

.nav div.nav-primary-list ul li a:hover,
.nav div.nav-primary-list ul li a:active {
  opacity: 0.8;
}

.nav-trigger {
  display: none;
}

.nav {
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/* dropdown css */
.navbar-caret-down-fill {
  height: 20px;
  width: 20px;
  color: #212a3e;
  padding-right: 5px;
}

.dropdown-text {
  border: none;
  outline: none;
  background-color: inherit;
  font-family: "Montserrat", Helvetica, sans-serif !important;
  text-decoration: none;
  margin: 0;
  line-height: 80px;
  color: #212a3e;
  cursor: pointer;
  text-align: center;
  font-size: 1.3rem;
}

.dropdown:hover .dropdown-text {
  opacity: 0.8;
}

.dropdown-content {
  margin-top: 0.25rem;
  display: none;
  position: absolute;
  background-color: #f1f6f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content-show {
  display: block;
}

.dropdown-content a {
  float: none;
  height: 3rem;
  line-height: 4rem;
  color: #212a3e;
  padding: 8px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #212a3e;
  color: #f1f6f9;
}

@media screen and (min-width: 1000px) {
  .nav-container {
    margin: 0;
  }
}

@media screen and (max-width: 1000px) {
  .nav-trigger {
    display: block;
  }

  .nav div.logo {
    margin-top: -18px !important;
    margin-left: -30px !important;
    width: 200px;
  }

  .logo-responsive {
    width: 160px !important;
    -webkit-transition: all 0.4s ease !important;
    transition: all 0.4s ease !important;
  }

  .nav-responsive {
    height: 55px;
    line-height: 55px;
    transition: all 0.4s ease;
  }

  .nav div.nav-primary-list {
    width: 100%;
    height: 0;
    opacity: 0;
    overflow: hidden;
  }

  .nav div.show-list {
    height: auto;
    opacity: 1;
    transition: transform 0.3s ease;
    transform: scaleY(1);
    transform-origin: top;
    background: rgba(0, 0, 0, 0.4);
    margin-top: 0.25rem;
  }

  .nav div.scroll-down-hide-list {
    height: auto;
    opacity: 1;
    transition: transform 0.3s ease;
    transform: scaleY(0);
    transform-origin: top;
    background: rgba(0, 0, 0, 0.4);
    margin-top: -1.25rem;
  }

  .nav div.scroll-up-hide-list {
    height: auto;
    opacity: 1;
    transition: transform 0.3s ease;
    transform: scaleY(0);
    transform-origin: top;
    background: rgba(0, 0, 0, 0.4);
    margin-top: 0.25rem;
  }

  .nav div.nav-primary-list ul {
    overflow-y: scroll;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    right: 0;
    left: 0;
    bottom: 0;
    float: right;
    background-color: #212a3e;
    background-position: center top;
  }
  .nav div.nav-primary-list ul li {
    width: 50%;
    border-bottom: 1px solid #f1f6f9;
    place-self: center;
    text-align: center;
    height: 4rem;
    line-height: 4rem;
    padding: 0px;
  }
  .nav div.nav-primary-list ul li a {
    text-align: center;
    width: 100%;
    font-size: 1.2rem;
    line-height: 1.2rem;
    color: #fff;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 0.4rem;
  }

  /* dropdown responsive css */
  .navbar-caret-down-fill {
    height: 20px;
    width: 20px;
    color: #fff;
    padding-right: 5px;
  }

  .dropdown {
    text-align: -webkit-center;
  }

  .dropdown-text {
    color: #fff;
    font-size: 1.2rem;
    line-height: 1.2rem;
    height: 3rem;
  }

  .dropdown-content {
    margin-top: 10px;
    padding-bottom: 10px;
    position: relative;
    background-color: #f1f6f9;
    box-shadow: none;
  }

  .dropdown-content a {
    color: #212a3e;
    text-align: center;
    height: 2.75rem;
    width: 50%;
    font-weight: bold;
    line-height: 2.75rem;
    padding-left: 0px;
    padding-right: 0.4rem;
    border-bottom: 1px solid #212a3e;
  }

  .dropdown-content a:hover {
    background-color: #f1f6f9;
    color: #212a3e;
  }
  /* end of dropdown responsive css */
}

.nav-trigger {
  cursor: pointer;
  width: 30px;
  height: 20px;
  margin: auto;
  position: absolute;
  right: 30px;
  top: 0;
  bottom: 0;
}

.nav-trigger-w-15 {
  width: 15px !important;
}

.nav-trigger-w-30 {
  width: 30px !important;
}

.nav-trigger i {
  background-color: #212a3e;
  border-radius: 2px;
  content: "";
  display: block;
  width: 100%;
  height: 4px;
}

.nav-trigger i:nth-child(1) {
  -webkit-animation: outT 0.8s backwards;
  animation: outT 0.8s backwards;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}

.nav-trigger i:nth-child(2) {
  margin: 5px 0;
  -webkit-animation: outM 0.8s backwards;
  animation: outM 0.8s backwards;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}

.nav-trigger i:nth-child(3) {
  -webkit-animation: outBtm 0.8s backwards;
  animation: outBtm 0.8s backwards;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}

.nav-trigger.active i:nth-child(1) {
  -webkit-animation: inT 0.8s forwards;
  animation: inT 0.8s forwards;
}

.nav-trigger.active i:nth-child(2) {
  -webkit-animation: inM 0.8s forwards;
  animation: inM 0.8s forwards;
}

.nav-trigger.active i:nth-child(3) {
  -webkit-animation: inBtm 0.8s forwards;
  animation: inBtm 0.8s forwards;
}

@-webkit-keyframes inM {
  50% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
  }
}

@keyframes inM {
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

@-webkit-keyframes outM {
  50% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
  }
}

@keyframes outM {
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

@-webkit-keyframes inT {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(9px) rotate(135deg);
  }
}

@keyframes inT {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(9px) rotate(0deg);
  }
  100% {
    transform: translateY(9px) rotate(135deg);
  }
}

@-webkit-keyframes outT {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(9px) rotate(135deg);
  }
}

@keyframes outT {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(9px) rotate(0deg);
  }
  100% {
    transform: translateY(9px) rotate(135deg);
  }
}

@-webkit-keyframes inBtm {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(-9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(-9px) rotate(135deg);
  }
}

@keyframes inBtm {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(-9px) rotate(0deg);
  }
  100% {
    transform: translateY(-9px) rotate(135deg);
  }
}

@-webkit-keyframes outBtm {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(-9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(-9px) rotate(135deg);
  }
}

@keyframes outBtm {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(-9px) rotate(0deg);
  }
  100% {
    transform: translateY(-9px) rotate(135deg);
  }
}
