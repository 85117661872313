html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #212a3e;
}

h1 {
  font-family: "Montserrat", Helvetica, sans-serif !important;
}

a {
  font-family: "Montserrat", Helvetica, sans-serif;
}

p {
  font-family: "Josefin Light", sans-serif;
}

/* width */
.w-1\/4 {
  width: 25%;
}

.w-1\/3 {
  width: 33.333333%;
}

.w-2\/5 {
  width: 40%;
}

.w-1\/2 {
  width: 50%;
}

.w-3\/5 {
  width: 60%;
}

.w-2\/3 {
  width: 66.666666%;
}

.w-3\/4 {
  width: 75%;
}

.w-5\/6 {
  width: 83.333333%;
}

.w-11\/12 {
  width: 91.666667%;
}

.w-near-full {
  width: 95%;
}

.w-full {
  width: 100%;
}

/* height */
.h-full {
  height: 100%;
}

.h-auto {
  height: auto;
}

.h-fit-content {
  height: fit-content;
}

/* padding */
.pl-5 {
  padding-left: 1.25rem;
}

.pr-5 {
  padding-left: 1.25rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.px-2 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-5 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

/* margin */
.mb-0 {
  margin-bottom: 0rem;
}

.mb-1-forced {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-4-forced {
  margin-bottom: 1rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mt-0 {
  margin-top: 0rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.my-0 {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.m-4 {
  margin: 1rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

/* min height */
.min-h-screen {
  min-height: 100vh;
}

/* grid */
.grid {
  display: grid;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

/* flex */
.flex {
  flex: auto;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

/* font weight */
.bold {
  font-weight: bold;
}

/* justify */
.justify-self-center {
  justify-self: center;
}

.justify-self-left {
  justify-self: left;
}

.justify-center {
  justify-content: center;
}

.justify-left-forced {
  justify-content: left !important;
}

.justify-items-right {
  justify-items: right;
}

.justify-items-center {
  justify-items: center;
}

/* cursor */
.pointer {
  cursor: pointer;
}

/* text align */
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.webkit-text-center {
  text-align: -webkit-center;
}

.webkit-text-right {
  text-align: -webkit-right;
}

/* align */
.align-content-baseline {
  align-content: baseline;
}

.align-content-center {
  align-content: center;
}

.align-content-flex-start {
  align-content: flex-start;
}

.align-items-center {
  align-items: center;
}

.align-self-center {
  align-self: center;
}

.vertical-align-middle {
  vertical-align: middle;
}

/* imported font */
@font-face {
  font-family: "Josefin Light";
  src: local("JosefinLight"),
    url("./fonts/josefin-sans.light-italic.ttf") format("truetype");
}

/* custom classes */
.docx-cover-letter {
  border: 0;
  width: 100%;
  height: 1100px;
}

.paperform-child-form {
  margin-top: 2rem;
  border: 0;
  width: 100%;
  height: 13650px;
}

.paperform-teen-form {
  margin-top: 2rem;
  border: 0;
  width: 100%;
  height: 17000px;
}

.paperform-adult-form {
  margin-top: 2rem;
  border: 0;
  width: 100%;
  height: 20200px;
}

.form-download {
  margin-top: 0;
  color: #394867;
  height: 45px;
  width: 45px;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  cursor: pointer;
}

.form-download:hover {
  opacity: 0.8;
}

.theme-alert-backdrop {
  position: absolute;
  inset: 0;
  background-color: #11182680;
}

.theme-alert {
  position: relative;
  z-index: 10;
  background-color: #f1f6f9;
  border-radius: 1rem;
  max-width: 50%;
  margin-top: 15vh;
  padding: 1rem;
  text-align: right;
}

.theme-alert-close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  background: #394867;
  font-size: 1.5rem;
  font-family: "Montserrat", Helvetica, sans-serif !important;
  font-weight: 600;
  color: #f1f6f9;
}

.theme-text-color-1 {
  color: #fff;
}

.theme-text-color-2 {
  color: #000;
}

.theme-text-color-3 {
  color: #212a3e;
}

.theme-text-color-4 {
  color: #394867;
}

.theme-text-color-5 {
  color: #f1f6f9;
}

.forced-theme-text-color-1 {
  color: #fff !important;
}

.theme-bg-color-1 {
  background-color: #fff;
}

.forced-theme-bg-color-1 {
  background-color: #fff !important;
}

.theme-bg-color-2 {
  background-color: #000;
}

.theme-bg-color-3 {
  background-color: #212a3e;
}

.theme-bg-color-4 {
  background-color: #394867;
}

.theme-bg-color-5 {
  background-color: #f1f6f9;
}

.page-container {
  padding-top: 105px;
  background: #f1f6f9;
  height: 100%;
  justify-content: center;
}

.no-padding-page-container {
  background: #f1f6f9;
  height: 100%;
  justify-content: center;
}

.home-bg-image-container {
  background-image: url("../public/cross-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.getting-started-bg-image-container {
  background-image: url("../public/worship.jpg");
  /* background-attachment: fixed; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position-y: 60% !important;
  height: 85vh;
}

.theme-content-container {
  padding: 1.25rem;
  padding-top: 0 !important;
}

.getting-started-content-container {
  padding: 1.25rem;
}

.page-break {
  padding-top: 10rem;
}

.bg-image-container-header {
  font-size: 4rem;
  font-family: "Josefin Light" !important;
  font-weight: 600;
  color: #fff;
  text-shadow: 2px 2px #212a3e;
  line-height: 4.5rem;
  padding-top: 3rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  margin-top: 0;
  margin-bottom: 0;
}

.bg-image-container-sub-header {
  font-size: 3rem;
  font-family: "Josefin Light" !important;
  font-weight: 600;
  color: #fff;
  text-shadow: 2px 2px #212a3e;
  line-height: 3.5rem;
  padding-top: 2.5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  margin-top: 0;
  margin-bottom: 0;
}

.home-content-container {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 16%;
  padding-bottom: 2rem;
  place-content: center;
  justify-content: center;
}

.home-content-inner-container {
  text-align: left;
  align-self: center;
  height: 100%;
  margin: 0.25rem;
  backdrop-filter: blur(5px);
  background-color: #f1f6f9;
  background-color: rgb(62, 54, 54, 0.35);
}

.home-content-icon {
  margin-top: 2.5rem;
  color: #394867;
  height: 60px;
  width: 60px;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.home-content-title {
  color: #f1f6f9;
  margin-top: 1rem;
  margin-bottom: 0.25rem;
  font-size: 2.25rem;
  line-height: 2.5rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.home-content-paragraph {
  color: #f1f6f9;
  margin-top: 0.75rem;
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.getting-started-content-title {
  padding-top: 5rem;
  font-size: 2.5rem;
  font-weight: 600;
  color: #fff;
  line-height: 3rem;
  padding-right: 0.25rem;
  padding-left: 0.25rem;
  margin-top: 0;
  margin-bottom: 0;
}

.getting-started-content-paragraph {
  padding-bottom: 5rem;
  font-size: 2rem;
  font-weight: 600;
  color: #fff;
  line-height: 2.5rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.theme-header-3 {
  font-size: 3.5rem;
  font-weight: 600;
  color: #212a3e;
  line-height: 4rem;
  margin-top: 0;
  margin-bottom: 0;
}

.theme-header-3-small {
  font-size: 2.5rem;
  font-weight: 600;
  color: #212a3e;
  line-height: 3rem;
  margin-top: 0;
  margin-bottom: 0;
}

.theme-text-3 {
  font-size: 2rem;
  font-weight: 600;
  color: #212a3e;
  line-height: 2.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.theme-text-3-small {
  font-size: 1.5rem;
  font-weight: 600;
  color: #212a3e;
  line-height: 2rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.theme-btn-4 {
  background: #394867;
  border: 0;
  border-radius: 3rem;
  padding: 0.75rem 3rem;
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  cursor: pointer;
}

.theme-btn-4:hover {
  opacity: 0.8;
}

.container-col-2,
.container-col-3 {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  place-content: center;
  justify-content: center;
}

.container-col-2-item {
  -ms-flex: 48%;
  flex: 48%;
  max-width: 50%;
}

.container-col-3-item {
  -ms-flex: 33%;
  flex: 33%;
  max-width: 33.33%;
}

.home-content-outer-container {
  -ms-flex: 24%;
  flex: 24%;
  max-width: 24%;
}

/* footer */
.footer {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  place-content: center;
  justify-content: center;
  background: #f1f6f9;
  padding-bottom: 2.75rem;
}

.footer-content-container {
  display: flex;
  -ms-flex: 33.3%;
  flex: 33.3%;
  max-width: 33.3%;
  place-content: center;
}

.footer-content-icon {
  margin-top: 0.75rem;
  color: #394867;
  height: 60px;
  width: 60px;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.footer-social-media-content-icon {
  margin-top: 0.75rem;
  height: 60px;
  width: 60px;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  cursor: pointer;
}

.facebook {
  color: #3b5998;
}

.instagram {
  border-radius: 15px;
  padding: 0 !important;
  color: #fff;
  background: #d6249f;
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
}

.twitter {
  color: #00acee;
}

.footer-separator {
  height: 1px;
  width: 95%;
  margin-top: 1rem;
  margin-bottom: 1.25rem;
  border-bottom: dotted 5px #394867;
}

.footer-content-address-title {
  color: #212a3e;
  margin-top: 0.5rem;
  margin-bottom: 0rem;
  font-size: 2.25rem;
  line-height: 2.5rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.footer-content-paragraph {
  color: #212a3e;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  cursor: pointer;
  text-decoration: none;
  font-family: "Josefin Light", sans-serif !important;
}

/* media queries */
/* large devices plus */
@media (min-width: 1100px) {
  .page-container,
  .theme-content-container,
  .getting-started-content-container {
    max-width: 80%;
  }
}

/* medium devices */
@media (max-width: 1100px) {
  .mt-0-f {
    margin-top: 0 !important;
  }

  .mt-5-forced {
    margin-top: 1.25rem !important;
  }

  .px-0-f {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .page-break {
    padding-top: 8rem;
  }

  .getting-started-bg-image-container {
    background-position-y: 100% !important;
    height: 65vh;
  }

  .bg-image-container-header {
    font-size: 2.5rem;
    line-height: 3rem;
    padding-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .bg-image-container-sub-header {
    font-size: 2rem;
    line-height: 2.5rem;
    padding-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .home-content-container {
    padding-top: 15%;
  }

  .home-content-outer-container {
    margin-top: 0.5rem;
    -ms-flex: 45%;
    flex: 45%;
    max-width: 45%;
  }

  .container-col-2-item,
  .container-col-3-item {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }

  .footer-content-icon {
    height: 35px;
    width: 35px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .footer-social-media-content-icon {
    height: 50px;
    width: 50px;
  }

  .footer-content-address-title {
    font-size: 1.25rem;
    line-height: 1.75rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .footer-content-paragraph {
    font-size: 1rem;
    line-height: 1.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

/* small devices */
@media (max-width: 800px) {
  .mt-0-f {
    margin-top: 0 !important;
  }

  .px-0-f {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .theme-content-container {
    padding: 0.75rem;
  }

  .getting-started-content-container {
    padding: 0.75rem;
  }

  .theme-alert {
    padding: 0.5rem;
    max-width: 85%;
  }

  .footer {
    padding-bottom: 1.75rem;
  }

  .footer-content-container {
    display: flex;
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
    justify-content: left;
    margin-bottom: 1rem;
  }

  .footer-content-icon {
    height: 45px;
    width: 45px;
    padding-left: 1rem;
    padding-right: 0.25rem;
  }

  .footer-social-media-content-icon {
    height: 30px;
    width: 30px;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .footer-content-address-title {
    font-size: 1.5rem;
    line-height: 2rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .footer-content-paragraph {
    font-size: 1.25rem;
    line-height: 1.75rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
}

/* mobile devices */
@media (max-width: 650px) {
  .mt-0-f {
    margin-top: 0 !important;
  }

  .px-0-f {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .page-break {
    padding-top: 5rem;
  }

  .home-bg-image-container {
    background: #f1f6f9;
    /* background-image: url("../public/mobile-cross-bg.jpg"); */
    /* background-position-x: 50 50%; */
    /* background-size: contain; */
    /* background-position: 50% 50%; */
    /* background-attachment: fixed; */
  }

  .getting-started-bg-image-container {
    /* background-position-y: -40vw !important; */
    height: 55vh;
  }

  .bg-image-container-header {
    font-size: 2rem;
    line-height: 2.5rem;
    padding-top: 5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .bg-image-container-sub-header {
    font-size: 1.5rem;
    line-height: 2rem;
    padding-top: 1rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .home-content-container {
    padding-top: 14%;
  }

  .home-content-outer-container {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }

  .theme-header-3 {
    font-size: 2.75rem;
    line-height: 3.25rem;
  }

  .theme-header-3-small {
    font-size: 2.25rem;
    line-height: 2.75rem;
  }

  .theme-text-3 {
    font-size: 1.75rem;
    line-height: 2rem;
  }

  .paperform-child-form {
    height: 14000px;
  }

  .paperform-teen-form {
    height: 17500px;
  }

  .paperform-adult-form {
    height: 20600px;
  }

  .footer {
    padding-bottom: 1.75rem;
  }

  .footer-content-container {
    display: flex;
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
    justify-content: left;
    margin-bottom: 1rem;
  }

  .footer-content-icon {
    height: 40px;
    width: 40px;
    padding-left: 1rem;
    padding-right: 0.25rem;
  }

  .footer-social-media-content-icon {
    height: 30px;
    width: 30px;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .instagram {
    border-radius: 10px;
  }

  .footer-content-address-title {
    font-size: 1.25rem;
    line-height: 1.75rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .footer-content-paragraph {
    font-size: 1rem;
    line-height: 1.25rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .docx-cover-letter {
    height: 1250px;
  }
}

@media (max-width: 600px) {
  .paperform-child-form {
    height: 15000px;
  }

  .paperform-teen-form {
    height: 17800px;
  }

  .paperform-adult-form {
    height: 20900px;
  }
}

@media (max-width: 550px) {
  .paperform-child-form {
    height: 15300px;
  }

  .paperform-teen-form {
    height: 18200px;
  }

  .paperform-adult-form {
    height: 21300px;
  }
}

@media (max-width: 500px) {
  .paperform-child-form {
    height: 15700px;
  }

  .paperform-teen-form {
    height: 18600px;
  }

  .paperform-adult-form {
    height: 21600px;
  }
}

@media (max-width: 475px) {
  .paperform-child-form {
    height: 16000px;
  }

  .paperform-teen-form {
    height: 19000px;
  }

  .paperform-adult-form {
    height: 21700px;
  }
}

@media (max-width: 450px) {
  .paperform-child-form {
    height: 16200px;
  }

  .paperform-teen-form {
    height: 19100px;
  }

  .paperform-adult-form {
    height: 21900px;
  }
}

@media (max-width: 445px) {
  .paperform-child-form {
    height: 16300px;
  }

  .paperform-teen-form {
    height: 19300px;
  }

  .paperform-adult-form {
    height: 22000px;
  }
}

@media (max-width: 440px) {
  .paperform-child-form {
    height: 16400px;
  }

  .paperform-teen-form {
    height: 19500px;
  }

  .paperform-adult-form {
    height: 22100px;
  }
}

@media (max-width: 435px) {
  .paperform-child-form {
    height: 16500px;
  }

  .paperform-teen-form {
    height: 19600px;
  }

  .paperform-adult-form {
    height: 22200px;
  }
}

@media (max-width: 430px) {
  .paperform-child-form {
    height: 16600px;
  }

  .paperform-teen-form {
    height: 19700px;
  }

  .paperform-adult-form {
    height: 22300px;
  }
}

@media (max-width: 425px) {
  .paperform-child-form {
    height: 16700px;
  }

  .paperform-teen-form {
    height: 19800px
  }

  .paperform-adult-form {
    height: 22400px;
  }
}

@media (max-width: 420px) {
  .paperform-child-form {
    height: 16700px;
  }

  .paperform-teen-form {
    height: 19800px;
  }

  .paperform-adult-form {
    height: 22450px;
  }
}

@media (max-width: 415px) {
  .paperform-child-form {
    height: 16800px;
  }

  .paperform-teen-form {
    height: 20000px;
  }

  .paperform-adult-form {
    height: 22600px;
  }
}

@media (max-width: 410px) {
  .paperform-child-form {
    height: 16900px;
  }

  .paperform-teen-form {
    height: 20100px;
  }

  .paperform-adult-form {
    height: 22700px;
  }
}

@media (max-width: 405px) {
  .paperform-child-form {
    height: 17050px;
  }

  .paperform-teen-form {
    height: 20200px;
  }

  .paperform-adult-form {
    height: 22800px;
  }
}

@media (max-width: 400px) {
  .docx-cover-letter {
    height: 1500px;
  }

  .paperform-child-form {
    height: 17150px;
  }

  .paperform-teen-form {
    height: 20450px;
  }

  .paperform-adult-form {
    height: 22900px;
  }
}

@media (max-width: 395px) {
  .paperform-child-form {
    height: 17450px;
  }

  .paperform-teen-form {
    height: 20550px;
  }

  .paperform-adult-form {
    height: 23000px;
  }
}

@media (max-width: 390px) {
  .paperform-child-form {
    height: 17600px;
  }

  .paperform-teen-form {
    height: 20800px;
  }

  .paperform-adult-form {
    height: 23100px;
  }
}

@media (max-width: 380px) {
  .paperform-child-form {
    height: 17800px;
  }

  .paperform-teen-form {
    height: 20950px;
  }

  .paperform-adult-form {
    height: 23200px;
  }
}

@media (max-width: 375px) {
  .paperform-child-form {
    height: 18000px;
  }

  .paperform-teen-form {
    height: 21050px;
  }

  .paperform-adult-form {
    height: 23350px;
  }
}

@media (max-width: 370px) {
  .paperform-child-form {
    height: 18050px;
  }

  .paperform-teen-form {
    height: 21150px;
  }

  .paperform-adult-form {
    height: 23450px;
  }
}

@media (max-width: 365px) {
  .paperform-child-form {
    height: 18150px;
  }

  .paperform-teen-form {
    height: 21250px;
  }
  .paperform-adult-form {
    height: 23550px;
  }
}

@media (max-width: 360px) {
  .paperform-child-form {
    height: 18250px;
  }

  .paperform-teen-form {
    height: 21350px;
  }
  .paperform-adult-form {
    height: 23700px;
  }
}

@media (max-width: 355px) {
  .paperform-child-form {
    height: 18500px;
  }

  .paperform-teen-form {
    height: 21650px;
  }

  .paperform-adult-form {
    height: 23800px;
  }
}

@media (max-width: 350px) {
  .paperform-child-form {
    height: 18650px;
  }

  .paperform-teen-form {
    height: 21850px;
  }

  .paperform-adult-form {
    height: 23950px;
  }
}

@media (max-width: 345px) {
  .paperform-child-form {
    height: 18750px;
  }

  .paperform-teen-form {
    height: 22100px;
  }

  .paperform-adult-form {
    height: 24300px;
  }
}
